var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("h2", [_vm._v("Tracks")]),
              _c(
                "b-table-simple",
                { staticClass: "mt-4", attrs: { striped: "", hover: "" } },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "col" } }, [_vm._v("#")]),
                      _c("th", { attrs: { scope: "col" } }, [_vm._v("Titel")]),
                      _c("th", { attrs: { scope: "col" } }, [_vm._v("Artiest")])
                    ])
                  ]),
                  _vm._l(_vm.release.Tracks, function(track, index) {
                    return _c(
                      "tr",
                      {
                        key: index,
                        class: { selected: _vm.selected == index },
                        on: {
                          click: function($event) {
                            return _vm.selectTrack(index)
                          }
                        }
                      },
                      [
                        _c("td", [_vm._v(_vm._s(track.TrackSeq))]),
                        _c(
                          "td",
                          { attrs: { scope: "row" } },
                          [
                            !track.ExistingData
                              ? [_vm._v(" " + _vm._s(track.Title) + " ")]
                              : _vm._e(),
                            track.ExistingData
                              ? [
                                  _vm._v(
                                    " " + _vm._s(track.ExistingData.Title) + " "
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "td",
                          { attrs: { scope: "row" } },
                          [
                            !track.ExistingData
                              ? [_vm._v(" " + _vm._s(track.Attribution) + " ")]
                              : _vm._e(),
                            track.ExistingData
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(track.ExistingData.Attribution) +
                                      " "
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    )
                  })
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "side-tracks",
                  style: {
                    height: _vm.sideTracksHeight + "px",
                    top: _vm.sideTracksTop + "px"
                  }
                },
                [
                  _c(
                    "b-table-simple",
                    { attrs: { striped: "", hover: "" } },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "col" } }, [_vm._v("#")]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("Tracks")
                          ])
                        ])
                      ]),
                      _vm._l(_vm.release.Tracks, function(track, index) {
                        return _c(
                          "tr",
                          {
                            key: index,
                            class: { selected: _vm.selected == index },
                            on: {
                              click: function($event) {
                                return _vm.selectTrack(index, false)
                              }
                            }
                          },
                          [
                            _c("td", [_vm._v(_vm._s(track.TrackSeq))]),
                            _c(
                              "td",
                              {
                                staticClass: "text-breakall",
                                attrs: { scope: "row" }
                              },
                              [
                                !track.ExistingData
                                  ? [
                                      _vm._v(" " + _vm._s(track.Title)),
                                      _c("br"),
                                      _c("em", [
                                        _vm._v(_vm._s(track.Attribution))
                                      ])
                                    ]
                                  : _vm._e(),
                                track.ExistingData
                                  ? [
                                      _vm._v(
                                        " " + _vm._s(track.ExistingData.Title)
                                      ),
                                      _c("br"),
                                      _c("em", [
                                        _vm._v(
                                          _vm._s(track.ExistingData.Attribution)
                                        )
                                      ])
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c("track-concept", {
                attrs: {
                  fieldDefs: _vm.fieldDefs.Track,
                  errors: _vm.trackErrors[_vm.selected],
                  trackCount: _vm.release.Tracks.length,
                  track: _vm.release.Tracks[_vm.selected],
                  release: _vm.release
                },
                on: {
                  copyToTracks: _vm.onCopyToTracks,
                  trackBusy: _vm.onTrackBusy,
                  errors: function($event) {
                    return _vm.receiveErrors(_vm.selected, $event)
                  }
                }
              }),
              _c(
                "b-button",
                {
                  staticClass: "mt-4 mr-2",
                  attrs: { disabled: _vm.selected < 1 },
                  on: {
                    click: function($event) {
                      return _vm.selectTrack(_vm.selected - 1)
                    }
                  }
                },
                [_vm._v("Vorige track")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4 mr-4",
                  attrs: {
                    disabled: _vm.selected == _vm.release.Tracks.length - 1
                  },
                  on: {
                    click: function($event) {
                      return _vm.selectTrack(_vm.selected + 1)
                    }
                  }
                },
                [_vm._v("Volgende track")]
              ),
              _c("br"),
              _vm._l(_vm.trackErrors, function(error, index) {
                return [
                  Object.keys(error).length
                    ? _c(
                        "b-alert",
                        {
                          key: index,
                          staticClass: "mt-4",
                          attrs: { show: "", variant: "danger" }
                        },
                        [
                          _vm._v(
                            " Track " +
                              _vm._s(index + 1) +
                              " bevat nog fouten. Selecteer de track en scroll naar boven om deze te corrigeren. "
                          )
                        ]
                      )
                    : _vm._e()
                ]
              }),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { disabled: !_vm.readyToProceed },
                  on: { click: _vm.proceed }
                },
                [_vm._v("Ga verder met de playlist")]
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }