<template>
    <b-container fluid>
      <b-row>
        <b-col>
          <h2>Tracks</h2>

          <b-table-simple striped hover class="mt-4">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Titel</th>
                <th scope="col">Artiest</th>
              </tr>
            </thead>
              <tr v-for="(track, index) in release.Tracks" :key="index" v-on:click="selectTrack(index)" :class="{ selected: selected == index }">
                <td>{{ track.TrackSeq }}</td>
                <td scope="row">
                  <template v-if="!track.ExistingData">
                    {{ track.Title }}
                  </template>
                  <template v-if="track.ExistingData">
                    {{ track.ExistingData.Title }}
                  </template>
                </td>
                <td scope="row">
                  <template v-if="!track.ExistingData">
                    {{ track.Attribution }}
                  </template>
                  <template v-if="track.ExistingData">
                    {{ track.ExistingData.Attribution }}
                  </template>
                </td>
              </tr>
          </b-table-simple>

          <div class="side-tracks" :style="{ height: sideTracksHeight + 'px', top: sideTracksTop + 'px' }">
            <b-table-simple striped hover>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Tracks</th>
                </tr>
              </thead>
                <tr v-for="(track, index) in release.Tracks" :key="index" v-on:click="selectTrack(index, false)" :class="{ selected: selected == index }">
                  <td>{{ track.TrackSeq }}</td>
                  <td scope="row" class="text-breakall">
                  <template v-if="!track.ExistingData">
                    {{ track.Title }}<br>
                    <em>{{ track.Attribution }}</em>
                  </template>

                  <template v-if="track.ExistingData">
                    {{ track.ExistingData.Title }}<br>
                    <em>{{ track.ExistingData.Attribution }}</em>
                  </template>
                  </td>
                </tr>
            </b-table-simple>
          </div>

          <track-concept
            @copyToTracks="onCopyToTracks"
            @trackBusy="onTrackBusy"
            @errors="receiveErrors(selected, $event)"
            :fieldDefs="fieldDefs.Track"
            :errors="trackErrors[selected]"
            :trackCount="release.Tracks.length"
            :track="release.Tracks[selected]"
            :release="release" />

          <b-button :disabled="selected < 1" @click="selectTrack(selected - 1)" class="mt-4 mr-2">Vorige track</b-button>
          <b-button :disabled="selected == release.Tracks.length - 1" @click="selectTrack(selected + 1)" class="mt-4 mr-4">Volgende track</b-button>
          <br>

          <template v-for="(error, index) in trackErrors">
            <b-alert v-if="Object.keys(error).length" :key="index" show variant="danger" class="mt-4">
              Track {{ index+1 }} bevat nog fouten. Selecteer de track en scroll naar boven om deze te corrigeren.
            </b-alert>
          </template>

          <b-button :disabled="!readyToProceed" @click="proceed" class="mt-4">Ga verder met de playlist</b-button>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
  import ReleaseConceptHelper from '@/mixins/ReleaseConceptHelper'
  import { generateAttribution } from '@/functions'

  export default {
    name: 'Tracks',
    components: {
      //'page-snippet': () => import('@/components/PageSnippet'),
      'track-concept': () => import('@/components/TrackConcept'),
      //'draggable': () => import('vuedraggable'),
    },
    mixins: [
      ReleaseConceptHelper 
    ],
    data () {
      return {
        receivedErrors: [],
        trackErrors: [],
        trackErrorCount: 0,
        selected: 0,
        sideTracksHeight: 0,
        sideTracksTop: 0,
        trackBusy: false,
      }
    },
    props: {
      release: Object,
      fieldDefs: Object,
    },
    watch: {
    },
    methods: {
      receiveErrors (selected, errors) {
        if (this.receivedErrors[selected] === undefined) {
          this.receivedErrors[selected] = {}
        }
        Object.assign(this.receivedErrors[selected], errors)
      },
      proceed () {
        let defs = this.fieldDefs.Track
        let errorcount = 0
        let isrcs = {}
        this.release.Tracks.forEach(track => {
          if (!track.ISRC) return
          let isrc = track.ISRC.replace(/[^a-z0-9]/gi, '').toUpperCase()
          if (isrc.length == 12) {
            if (!isrcs[isrc]) {
              isrcs[isrc] = []
            }
            isrcs[isrc].push(track)
          }
        })

        let trackErrors = this.release.Tracks.map((track, index) => { 
          let errors = {}
          let isrcA = track.ISRC.replace(/[^a-z0-9]/gi, '').toUpperCase()

          if (isrcA && isrcA.length == 12) {
            this.release.Tracks.forEach(trackB => {
              let isrcB = trackB.ISRC.replace(/[^a-z0-9]/gi, '').toUpperCase()
              if (track != trackB && isrcA == isrcB) {
                if (!errors['ISRC'])
                  errors['ISRC'] = []

                errors['ISRC'].push('ISRC wordt ook gebruikt op track ' + trackB.TrackSeq)
              }
            })
          }
          
          if (!track.UseExisting) {
            this.validate(track, defs, 'ISRC', errors)
            this.validate(track, defs, 'TitleLanguage', errors)
            this.validate(track, defs, 'LyricsLanguage', errors)
            this.validate(track, defs, 'Title', errors)
            this.validate(track, defs, 'Attribution', errors)
            this.validate(track, defs, 'Tags.MainGenre', errors)
            this.validate(track, defs, 'PLine', errors)
            this.validate(track, defs, 'CLine', errors)
            this.validate(track, defs, 'BPM', errors)
            this.validate(track, defs, 'MovementNr', errors)
            this.validate(track, defs, 'Attributes.PreviewDuration', errors)
            this.validate(track, defs, 'Attributes.PreviewStartSeconds', errors)
            this.validateContributors(track, errors)
            this.validateOtherInfluences(track, errors)

            if (!track.Rights || !track.Rights[0].Territory) {
              errors['Territory'] = ['Territory is een verplicht veld']
            }
          }

          for (let [key, value] of Object.entries(this.receivedErrors[index] || {})) {
            if (value.length > 0) {
              if (errors[key] === undefined) {
                errors[key] = []
              }
              errors[key] = errors[key].concat(value)
            }
          }

          errorcount += Object.keys(errors).length
          return errors
        })

        this.$set(this, 'trackErrors', trackErrors)
        this.trackErrorCount = errorcount

        if (errorcount == 0)
          this.$emit('proceed')
      },
      /*
      checkTrack (index = false) {
        if (!this.trackErrors) {
          this.trackErrors = this.release.Tracks.map(() => { return [] })
        }

        if (!index) index = this.selected
        let track = this.release.Tracks[index]
        let errors = []

        this.$set('')
      },*/
      selectTrack (index, scrollUp = true) {
        if (this.trackBusy) return

        this.selected = index
        if (scrollUp)
          this.$root.scroll()
      },
      onTrackBusy (state) {
        this.trackBusy = state
      },
      onResize () {
        this.sideTracksTop = document.getElementById('sidebar-info').getBoundingClientRect().bottom + 10
        this.sideTracksHeight = document.getElementById('sidebar').clientHeight -this.sideTracksTop 
      },
      onCopyToTracks(section, field, data) {
        let src = this.release.Tracks[this.selected]
        if (section == null) {
          let value = src[field]
          console.log('Copying to all tracks', field, value)

          this.$set(this.release, 'Tracks', this.release.Tracks.map(track => {
            track[field] = value
            return track
          }))
        }

        if (section == 'Tags') {
          let value = this.getTag(src, field, true)
          console.log('Copying tag to all tracks', field, value)

          this.$set(this.release, 'Tracks', this.release.Tracks.map(track => {
            this.setTag(track, field, value)
            return track
          }))
        }

        if (section == 'Attributes') {
          let value = this.getAttribute(src, field, true)
          console.log('Copying attribute to all tracks', field, value)

          this.$set(this.release, 'Tracks', this.release.Tracks.map(track => {
            this.setAttribute(track, field, value)
            return track
          }))
        }

        if (section == 'Contributor') {
          console.log('Copying contributor to all tracks', data)
          
          this.$set(this.release, 'Tracks', this.release.Tracks.map((track, index) => {
            if (index == this.selected) return track

            let contribs = track.Contributors || []
            if (contribs.every(c => { return JSON.stringify(data) != JSON.stringify(c) })) {
              contribs.push(data)
            }
            this.$set(track, 'Contributors', contribs)
            this.$set(track, 'Attribution', generateAttribution(track))
            return track
          }))
        }

        if (section == 'Territory') {
          console.log('Copying territory to all tracks')

          this.$set(this.release, 'Tracks', this.release.Tracks.map(track => {
            this.$set(track, 'Rights', [...src.Rights])
            return track
          }))
        }
      }
    },
    computed: {
      readyToProceed () {
        return true
      }
    },
    mounted () {
      this.onResize()
    },
    created() {
      window.addEventListener("resize", this.onResize);
    },
    destroyed() {
      window.removeEventListener("resize", this.onResize);
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variables';

  tr {
    cursor: pointer
  }

  tr.selected {
    background-color: $color-rowselected !important;
  }

  .side-tracks {
    table {
      background-color: #eee;
    }
    position: fixed;
    left: 0px;
    width: 215px;
    bottom: 10px;
    overflow-y: auto;
  }

  .text-breakall {
    word-break: break-all;
  }
</style>